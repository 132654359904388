<template>
  <CsTableCard
    :loading="loading"
    card-header="Elenco Opere"
    :rows="rows"
    :columns="columns"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <cs-table-action-column-default
        :show-actions="canDetails"
        :props="props"
      >
        <cs-table-action-item-details
          :show="canDetails"
          :row="props.row"
          @click="detailsRow"
        />
      </cs-table-action-column-default>

    </template>

  </CsTableCard>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      columns: [
        {
          label: 'Codice',
          field: 'codice',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca..',
          },
        },
        {
          label: 'Nome',
          field: 'nome',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca..',
          },
        },
        {
          label: 'Numero',
          field: 'numero',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca..',
          },
        },
        {
          label: 'Codice IOP',
          field: 'codiceIop',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca..',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      canDetails: this.$grants.LIVELLO2_VISUALIZZA_DETTAGLIO.can,
    }
  },
  created() {
    this.$remote.opere.all()
      .then(result => { this.rows = result.data })
      .catch(err => this.showReposonseMessage(err))
      .finally(() => { this.loading = false })
  },
  methods: {
    detailsRow(params) {
      this.$routing.LV_DETTAGLIO.push({ id: params.id })
    },
  },
}
</script>
